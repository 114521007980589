import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store'
import { Provider } from 'react-redux'
import {  PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PAYPAL_CLIENT_ID } from './config/config';
const root = ReactDOM.createRoot(document.getElementById('root'));
const initialOptions = {
  clientId:PAYPAL_CLIENT_ID,
  currency: "USD",
  intent: "capture",
}; 

root.render(
  <Provider store={store}>
    <PayPalScriptProvider options={initialOptions} >

      <App />
    </PayPalScriptProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
