import { Container } from "react-bootstrap";
import { adClient } from '../common/adConfig'
import { useEffect, useRef, useState } from "react";
import { adConfig } from "../config/config";
import AdComponent from "../common/AdComponent";

const AboutUs = () => {
    const adData = adConfig["AboutUs"]; // Get specific page ad config
    return (
        <div className="about-outer-section">
            {/* <AdComponent adId={adData.ids[0]} dimension={adData.dimensions[0]} className={"ads-banner-top"} /> */}
            {window.innerWidth <= 768 ? (
                <AdComponent adId={adData.ids[3]} dimension={adData.dimensions[3]} className={"ads-banner-top"} />) :
                (
                    <AdComponent adId={adData.ids[0]} dimension={adData.dimensions[0]} className={"ads-banner-top"} />)}

            <AdComponent adId={adData.ids[1]} dimension={adData.dimensions[1]} className={"ads-home-right"} />

            <AdComponent adId={adData.ids[2]} dimension={adData.dimensions[2]} className={"ads-home-left"} />
            <section className="common-padding about-us-section">
                <Container>
                    <div className="about-area-content">
                        <h2>About Us</h2>
                        <p><a target="black" href="https://betfolio.co/">BetFolio</a> is a gamified crypto prediction market, offering a simple and engaging way to bet on various crypto-themed and topical predictions.  </p>
                        <p>We feature predictions ranging from binary outcomes to multi-option polls, focusing on topics relevant to crypto natives, as well as sports and pop culture fans.
                        </p>
                        <h3>Prize Pool Predictions</h3>
                        <p>BetFolio is the first prediction market where you do not need to wager any of your own funds. Instead of traditional bets, you cast votes on-chain to predict future outcomes.
                        </p>
                        <p>We offer a unique format called ‘Prize Pool Predictions’. Users pay a small entry fee to place bets. If your prediction is correct, you win a share of the token prize pool provided by BetFolio or our sponsoring partners.</p>

                        <h3>Prediction Types</h3>
                        <h5>We offer the following types of prize pool predictions:</h5>
                        <div className="my-3">
                            <h6 className="my-2"><b>Solo Challenge Predictions:</b>
                            </h6>
                            <p>In our Solo challenge predictions, the first person to bet pays the lowest fee. Once the first bet is placed, others must pay a higher fee to place extra bets. Multiple winners share the prize pool. </p>

                        </div>
                        <div className="my-3">
                            <h6 className="my-2"><b>Multi-Choice Prize Pool Predictions:
                            </b>
                            </h6>
                            <p>Select the correct 3 or 4 options in a multichoice prediction poll to win up to $150 in hourly prizes and up to $3,000 in daily prizes. Multiple winners share the prize pool.
                            </p>
                            <p>All prize pool predictions are split into 6 types based on the amount of tokens in their pools:
                                Pebbles, Pearls, Sapphires, Rubies, Emeralds and Diamonds.
                            </p>
                            <img src={require("../assets/images/about-image-top.png")} />
                            <b>Examples: </b>
                            <p>1. Prediction Polls: <a href="https://betfolio.co/coinmarketcap/0x72ac293867d2b447041f05d0d0e77da015d9cba8" target="blank">“Which L2 token will have the highest 24hr return?: ARB, MANTA, MATIC, MNT, or OP”</a></p>
                            <p>2. Pop Culture predictions: <a href="https://betfolio.co/lunarcrush/0xd1f2a0b022afb1a580173b7f7daf0c0b5a993de9" target="blank">“Which celebrity will have the highest topic rank on LunarCrush by June 6th?: Taylor Swift, Ronaldo, Elon Musk, Drake, or BTS”</a></p>
                            <p>3. MultiChoice Predictions: <a href="https://betfolio.co/multi-choice-predictions/0xaf9dde96b2d0aea8d47b54041a33dc05e8e6eaf2" target="blank">Which 3 coins will have the highest 1hr return?
                            </a></p>
                        </div>
                    

                       
                        <p>Prediction timelines are 1 hour, 1 day, 1 week or 1 month.
                        </p>
                        <p>Each day around 8 PM EST (12 AM UTC), our Chainlink oracle contracts pull data from sources like Coinmarketcap, Defillama, LunarCrush, Dappradar and Twitter to execute prediction results. </p>

                        <h3>FOLIO Tokenized Points</h3>

                        <p>FOLIO are tokenized points redeemable for our BetFolio NFTs which allow you to earn a <a href="https://betfolio-discount-nft-25.nfts2.me/" target="blank">25%</a> and <a href="https://betfolio-discount-nft-50.nfts2.me/" target="blank">50%</a> discount on BetFolio platform fees for 30 days.
                        </p>

                        <p>You can earn FOLIO by accumulating <a href="https://betfolio.co/leaderboard" target="blank">points</a> when you place or win a bet on any prediction. Once you have a minimum 100 points, you can convert it to FOLIO, and then redeem your FOLIO for an NFT on our <a href="https://betfolio.co/folio-redeem" target="blank">redemption page.</a> </p>

                        <p>Make sure your wallet is connected to the Polygon network to check your points and FOLIO balance and to convert or redeem.</p>
                        <p>The maximum amount of FOLIO that can be redeemed by a single user in a 30 day period is 10,000 FOLIO.</p>

                        <h3>How To Bet on a Prediction</h3>
                        <p>The following videos demonstrate how to place a bets on different predictions: 
                        </p>

                        <div className="mb-3">
                            <p className="pb-0">Group Prize pool prediction:</p>
                            <a href="https://youtu.be/oH4zCkA6Gt8?si=SLVI7-MQQMBJJQg0" target="blank">https://youtu.be/oH4zCkA6Gt8?si=SLVI7-MQQMBJJQg0 </a>
                        </div>

                        <div className="mb-3">
                            <p className="pb-0">Mulit-Choice Prize pool prediction:</p>
                            <a href="https://youtube.com/shorts/5SIdITcuMLg?si=oePUiWfjCiDSnLsa" target="blank">https://youtube.com/shorts/5SIdITcuMLg?si=oePUiWfjCiDSnLsa  </a>
                        </div>

                        <h3>How Payouts Work for Predictions</h3>
                        <p>The expected payout for predictions is simply the total amount of funds in the prize pool divided by the number of winners.
                        </p>
                        <img src={require("../assets/images/about-image1.png")} />
                        <h3>Predictions Page Layout</h3>
                        <p>All predictions have a start date and time and an end date and time. You can view this on the prediction page.</p>
                        <img src={require("../assets/images/about-image2.png")} />
                        <p>The ‘Betting Window’ is the period of time in which users are allowed to place a bet. This is typically from the moment the prediction is launched until a few days or hours before the end date and time. </p>
                        <p>Once the betting window closes, you will no longer be able to place a bet and must wait until the result is shown. Prediction results are published and funds distributed to users 1 hour after the prediction end date and time. For hourly predictions, results are published 10 minutes after the prediction end time.</p>
                        <img src={require("../assets/images/about-image3.png")} />
                        <p>Under ‘Prediction leader-board’ you can see the list of wallets that made bets and the option(s) they bet on. </p>
                        <img src={require("../assets/images/about-image4.png")} />
                        <p>You can also see your expected payout for a particular betting option based on the amount of tokens in the prize pool divided by the number of people who bet on that option:</p>
                        <img src={require("../assets/images/about-image5.png")} />
                        <p>For any questions, please fill out our contact <a href="https://betfolio.co/contact-form" target="blank">form</a>, reach out to <a href="mailto:info@betfolio.co">info@betfolio.co</a> or contact us on <a href="https://t.me/betfoliox" target="blank">Telegram.</a></p>
                    </div>
                </Container>
            </section>

        </div>
    );
};
export default AboutUs