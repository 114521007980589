import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CommonLayout from "../common/CommonLayout";
import Home from "../component/Home";
import PredictionDetail from "../component/PredictionDetail";
import ConnectWalletPopup from "../common/ConnectWalletPopup";
import ComingSoon from "../common/ComingSoon";
import TransactionManagement from "../component/TransactionManagement";
import ContactForm from "../component/ContactForm";
import AboutUs from "../component/AboutUs";
import TermServices from "../component/TermServices";
import PrivacyPolicy from "../component/PrivacyPolicy";
import LeaderBoard from "../component/LeaderBoard";
import RiskFreePrediction from "../component/RiskFreePrediction";
import MintNft from "../component/mintNft";
import ReferralBoard from "../component/ReferralBoard";
import Redem from "../component/Redem";
import ProfileDetail from "../common/ProfileDetail";
import TransferFunds from "../component/TransferFunds";
import CustomPlaceBet from "../component/CustomPlaceBet";
import Marketplace from "../component/Marketplace";
import MarketplaceDetails from "../component/MarketplaceDetails";
// import MintNft from "../component/MintNft";


const Routing = () => {

  function renderCommonLayout(element) {
    return (<CommonLayout>{element}</CommonLayout>)
  }

  return (
    <Router>
      <Routes>
        <Route path="/custom-place-bet" element={renderCommonLayout(<CustomPlaceBet />)} />
        <Route path="/transfer-funds" element={renderCommonLayout(<TransferFunds />)} />
        <Route path="/" element={renderCommonLayout(<Home />)} />
        <Route path="/referral/:referralCode" element={renderCommonLayout(<Home />)} />
        <Route path="/:dataProvider" element={renderCommonLayout(<Home />)} />
        <Route path="/:dataProvider/:address" element={renderCommonLayout(<PredictionDetail />)} />
        <Route path="/mintNft" element={renderCommonLayout(<MintNft />)} />
        <Route path="/about-us" element={renderCommonLayout(<AboutUs />)} />
        <Route path="/how-it-works" element={renderCommonLayout(<ComingSoon />)} />
        <Route path="/contact-form" element={renderCommonLayout(<ContactForm />)} />
        <Route path="/transaction-management" element={renderCommonLayout(<TransactionManagement />)} />
        <Route path="/terms-services" element={renderCommonLayout(<TermServices />)} />
        <Route path="/privacy-policy" element={renderCommonLayout(<PrivacyPolicy />)} />
        <Route path="/leaderboard" element={renderCommonLayout(<LeaderBoard />)} />
        <Route path="/referrals" element={renderCommonLayout(<ReferralBoard />)} />
        <Route path="/redeem" element={renderCommonLayout(<Redem />)} />
        <Route path="/risk-free-prediction" element={renderCommonLayout(<RiskFreePrediction />)} />
        <Route path="/profileDetail" element={renderCommonLayout(<ProfileDetail />)} />
        <Route path="/marketplace" element={renderCommonLayout(<Marketplace />)} />
        <Route path="/nft-details/:tokenId" element={renderCommonLayout(<MarketplaceDetails />)} />
      </Routes>
    </Router>
  );
};

export default Routing;
