import Select from "react-select";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { apiService } from "../service/api.service";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import { ThreeDotSpinner } from "./loader";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatBalanceWithDecimals ,sumAmountsBySymbol} from "../common/connectWallet";

const Marketplace = () => {
  const [selectedStatus, setSelectedStatus] = useState({ value: "", label: "For sale" })
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(12);

  const [marketplaceNfts, setMarketplaceNfts] = useState([]);
  const navigate = useNavigate();
  const walletAddress = useSelector((state) => state.reducer.walletAddress)
  const [is_walletConnected, setis_walletConnected] = useState(localStorage.getItem("is_walletConnected"));

  const TotalPrizePool = (data) => {
    const aggregatedData = sumAmountsBySymbol(data);
  
    // Format the data as "amount symbol" strings
    const displayArray = aggregatedData.map(
      (item) => `${(item.amount / Math.pow(10, item.decimal)).toLocaleString()} ${item.symbol}`
    );
  
    return (
      <h6>
        Total Prize Pool:{" "}
        <span>
          {displayArray.join(", ")}
        </span>
      </h6>
    );
  };
  
  console.log("walletAddress",walletAddress)

  const statusFilterOptions = [
    { value: "", label: "For sale" },
    { value: "sold", label: "Sold" },
    ...(is_walletConnected ? [{ value: "mynft", label: "My listed NFTs" }] : []),
    { value: "expired", label: "Expired" }
    
  ];


  useEffect(() => {
    getMarketplaceNfts(page, selectedStatus.value,pageLimit);
  }, [])

  async function getMarketplaceNfts(page, status,pageLimit) {
    try {
      const response = await apiService.getMarketplaceNfts(page - 1, status,pageLimit);
      if (response.status === 200) {
        setMarketplaceNfts(response?.data);
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      if (error?.response?.status === 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      }
    }
  }


  function handleChangeStatus(event) {
    setSelectedStatus(event);
    setPage(1)
    getMarketplaceNfts(1, event.value,pageLimit);
    setShowLoader(true);
  }

  function handlePageChange(page) {
    setPage(page)
    getMarketplaceNfts(page, selectedStatus.value,pageLimit);
    setShowLoader(true);
  }



  return (
    <>
      {showLoader ? <ThreeDotSpinner /> : ""}
      <section className="marketplace-section profile-details-section">
        <Container>
          <div className="marketplace-content-area">
            <Row className="mb-5">
              <Col md={12} lg={5} xl={7}>
                <h2>Marketplace</h2>
                <p><i>Discover and trade open betting positions as NFTs. When the prediction ends and results are published, the NFT holder will receive the prize pool reward if the bet wins.</i></p>
              </Col>
              <Col sm={12} md={12} lg={7} xl={5}>
                <div className="explore-top-filter">
                  <Form.Group
                    className="filter-area-top"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Filter:</Form.Label>
                    <Select
                      options={statusFilterOptions}
                      defaultValue={selectedStatus}
                      value={selectedStatus}
                      // defaultMenuIsOpen={true}
                      onChange={(e) => handleChangeStatus(e)}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
          </div>
          {/* marketplace-area */}
          <div className="marketplace-area">
            <Row>
              {marketplaceNfts?.data?.length > 0 ? marketplaceNfts?.data.map(item => {
                return (<Col sm={12} md={6} lg={4} xl={3} className="margin-btm" onClick={() => navigate(`/nft-details/${item.tokenId}`)}>
                  <div className="marketplace-outer">
                    <div className="marketplace-header">
                      {item?.predictions.map(childItem => {
                        return (<img className={item?.predictions.length == 1 ? "nft-one-module" : item?.predictions.length == 2 ? "nft-two-module" : ""} src={childItem.image} />)
                      })}
                    </div>
                    <div className="marketplace-body">
                      <h6>
                        Total Number of Bets Bundled:  <span>{item?.predictionCount}</span>
                      </h6>
                      {TotalPrizePool(item?.predictions)}
                      <h6>
                        NFT Price: <span>{item?.price / 10 ** 6} USDC</span>
                      </h6>
                      {console.log("selectedStatus",selectedStatus)}
                      { selectedStatus?.value == "expired" && (new Date() >= new Date(new Date(item?.predictions?.[0]?.endDateTime).getTime() - 10 * 60 * 1000)) &&
                      <h6 className="nft_expired">
                        Expired
                      </h6>
                      }

                      {(new Date() >= new Date(new Date(item?.predictions?.[0]?.endDateTime).getTime() - 10 * 60 * 1000)) ? "": walletAddress && item?.owner == `${walletAddress}`?.toLowerCase() && item?.sold ?
                        <Button type="button" className="marketplace-btn" variant="unset">Sell</Button> :
                        walletAddress && item?.owner == `${walletAddress}`?.toLowerCase() ? "" :
                          <Button type="button" className="marketplace-btn" variant="unset">
                            {item?.sold ? "Sold" : "Buy"}
                          </Button>}
                    </div>
                  </div>
                </Col>)

              }) : <Col md={12} lg={12} xl={12} className="mb-4">
                <div className="no-data-area main-no-data">
                  <img
                    src={require("../assets/images/no-data.gif")}
                    alt="img"
                  />
                  <p>More NFTs Coming Soon</p>
                </div>
              </Col>}
            </Row>
            {marketplaceNfts?.metadata?.totalDocuments > pageLimit ? <Pagination
              activePage={page}
              itemsCountPerPage={pageLimit}
              totalItemsCount={marketplaceNfts?.metadata?.totalDocuments}
              pageRangeDisplayed={5}
              onChange={(e) => handlePageChange(e)}
              prevPageText={"Prev"}
              nextPageText={"Next"}
            /> : ""}
          </div>
        </Container>
      </section>
    </>
  );
};

export default Marketplace;
