import { Button, Col, Container, Modal, Row, Tab, Tabs, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import {
    appUrl,
    chainId,
    folioContractAddress,
    formatWalletAddress,
    tokenAddress,
    web3auth,
    walletServicesPlugin,
    showCheckout,
    adConfig,
} from "../config/config";
import { getPublicCompressed } from "@toruslabs/eccrypto";
import Web3 from "web3";
import { tokenAbi } from "../config/tokenAbi";
import { useSelector } from "react-redux";
import RPC from "./web3RPC";
import { apiService } from "../service/api.service";
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import AdComponent from "./AdComponent";
import CheckOutPayPal from "./CheckOutPayPal";
// import { WalletServicesPlugin } from "@web3auth/wallet-services-plugin";
import swal from "sweetalert";
import Pagination from "react-js-pagination";

const ProfileDetail = () => {
    const [selectedStatus, setSelectedStatus] = useState({ value: "owned", label: "Owned" })
    const [copySuccess, setCopySuccess] = useState("");
    const [copyReferral, setCopyReferral] = useState("");
    const [activeTab, setActiveTab] = useState("profile-details");
    const [isLoading, setIsLoading] = useState(true);


    const [privateKeySuccess, setPrivatekeySuccess] = useState("");
    const [privateKey, setPrivateKey] = useState(null);
    const [showShareReferral, setShowShareReferral] = useState(false);
    const [shareReferralUrl, setShareReferralUrl] = useState(null);
    const title = `Hey! Checkout this Referral.`;

    const [folioBalance, setFolioBalance] = useState("0.00");
    const web3AuthProvider = useSelector(
        (state) => state.reducer.web3AuthProvider
    );
    const navigate = useNavigate();

    const copyToClipboard = (text, setSuccessCallback) => {
        if (text) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    setSuccessCallback("Copied!");
                    setTimeout(() => setSuccessCallback(""), 2000);
                })
                .catch((err) => console.error("Failed to copy: ", err));
        }
    };

    useEffect(() => {
        // Check if the URL contains the 'nft' parameter
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("nft")) {
            setActiveTab("nft-holding");
        }
    }, []);

    useEffect(() => {
        if (!localStorage.getItem("user_type")) {
            window.location.href = "/";
        }
        if (web3AuthProvider) {
            getUserInfo();
        }
        getUserDataInfo();
    }, [web3AuthProvider]);

    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(12);

    const [showLoader, setShowLoader] = useState(true);
    const [myNfts, setMyNft] = useState([]);

    useEffect(() => {
        getMyNfts(page,"all");
    }, [])


    async function getMyNfts(page,filter) {
        setIsLoading(true); // Start loading
        try {
            const response = await apiService.getMyNfts(page - 1, pageLimit , filter);
            if (response.status === 200) {
                setMyNft(response?.data);
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                swal({ text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            }
        } finally {
            setIsLoading(false); // Stop loading
        }
    }


    function handleFilterStatus(event) {
        console.log("event>>>",event?.value);
        setSelectedStatus(event);
        setPage(1)
        getMyNfts(page,event?.value);
        // setShowLoader(true);
      }



    function handlePageChange(page) {
        setPage(page)
        getMyNfts(page);
        setShowLoader(true);
      }

    const [userDetail, setUserDetail] = useState();
    // const [walletAddress, setWalletAddress] = useState('');
    const walletAddress = useSelector((state) => state.reducer.walletAddress);

    const [balance, setBalance] = useState();
    const [userData, setUserData] = useState();
    const [isRunning, setIsRunning] = useState(true);
    const [maticPriceInUSD, setMaticPriceInUSD] = useState();

    useEffect(() => {
        // Function to call the API
        const callApi = async () => {
            const response = await apiService.getUSDMaticPrice();

            setMaticPriceInUSD(response.data);
        };

        // Call API every 30 seconds
        const intervalId = setInterval(() => {
            if (isRunning) {
                callApi();
            }
        }, 30000);

        // Stop after 2 minutes
        const timeoutId = setTimeout(() => {
            setIsRunning(false);
            clearInterval(intervalId); // Clear interval
            console.log("Timer stopped after 2 minutes.");
        }, 120000);
        callApi();

        // Cleanup function
        return () => {
            clearInterval(intervalId);
            clearTimeout(timeoutId);
        };
    }, [isRunning]);

    const getUserDataInfo = async () => {
        try {
            const response = await apiService.userDetailData();

            if (response.status === 200) {
                setUserData(response.data.data.userData);
                setShareReferralUrl(
                    `${appUrl}/referral/${response.data.data.userData.referral_code}`
                );
            }
        } catch (error) { }
    };

    const getUserInfo = async () => {
        if (web3auth.provider) {
            const rpc = new RPC(web3AuthProvider);
            // const address = await rpc.getAccounts();
            // setWalletAddress(address)
            const balance = await rpc?.getBalance();
            setBalance(balance);
            const user = await web3auth.getUserInfo(); // Fetch user info from Web3Auth
            setUserDetail(user);
        }
    };

    const exportPrivateKey = async () => {
        try {
            const appScopedPrivKey = await web3auth?.provider?.request({
                method: "eth_private_key",
            });
            const appPubKey = getPublicCompressed(
                Buffer.from(appScopedPrivKey.padStart(64, "0"), "hex")
            ).toString("hex");
            setPrivateKey(appScopedPrivKey);
            setTimeout(() => {
                setPrivateKey(null);
            }, 10000);
            console.log("Private Key:", appScopedPrivKey);
            console.log("Public Key:", appPubKey);
        } catch (error) {
            console.error("Failed to export private key:", error);
        }
    };

    const getFolioBalance = async () => {
        try {
            let web3 = new Web3(web3AuthProvider);
            let folioContract = new web3.eth.Contract(tokenAbi, folioContractAddress);
            const balance = await folioContract.methods
                .balanceOf(walletAddress)
                .call();
            let decimals = await folioContract.methods.decimals().call();
            let res = Number(balance) / 10 ** Number(decimals);
            setFolioBalance(res); // agar balance null ya empty ho, to 0.00 dikhaye
        } catch (error) {
            console.error("Error fetching balance:", error);
            setFolioBalance("0.00"); // error case mein bhi 0.00 dikhao
        }
    };

    const [usdcBalance, setUsdcBalance] = useState("0.00"); // State to store USDC balance
    const statusFilterOptions = [
        
        { value: "owned", label: "Owned" },
        { value: "created", label: "Created" }
        
      ];
    const getUSDCBalance = async () => {
        try {
            let web3 = new Web3(web3AuthProvider); // Assuming MetaMask or a similar provider
            let usdcContract = new web3.eth.Contract(tokenAbi, tokenAddress);
            let balance = await usdcContract.methods.balanceOf(walletAddress).call();
            let decimals = await usdcContract.methods.decimals().call();
            let res = Number(balance) / 10 ** Number(decimals);
            console.log("USDC Balance:", res);
            setUsdcBalance(parseInt(res)); // Store USDC balance in state
            return balance;
        } catch (error) {
            console.error("balance Error fetching USDC balance:", error);
        }
    };

   

    useEffect(() => {
        if (web3AuthProvider && !walletAddress?.accounts && walletAddress) {
            getFolioBalance(); // Fetch FOLIO balance on component mount
            getUSDCBalance();
        }
    }, [walletAddress, web3AuthProvider]);
    const adData = adConfig["profileDetail"]; // Get specific page ad config

    return (
        <>
            {/* <div className="ads-banner-top" id="ads-banner-top"></div>
            <div id="ads-home-right"></div>
            <div id="ads-home-left"></div> */}
            {window.innerWidth <= 768 ? (
                <AdComponent
                    adId={adData.ids[3]}
                    dimension={adData.dimensions[3]}
                    className={"ads-banner-top"}
                />
            ) : (
                <AdComponent
                    adId={adData.ids[0]}
                    dimension={adData.dimensions[0]}
                    className={"ads-banner-top"}
                />
            )}

            <AdComponent
                adId={adData.ids[1]}
                dimension={adData.dimensions[1]}
                className={"ads-home-right"}
            />

            <AdComponent
                adId={adData.ids[2]}
                dimension={adData.dimensions[2]}
                className={"ads-home-left"}
            />
            <section className="profile-detail-area profile-details-section ">
                <Container>
                    <div className="dashboard-area-heading management-heading">
                    
                        {/* tab section */}
                        <Tabs
                             activeKey={activeTab}
                             onSelect={(k) => setActiveTab(k)}
                            id="uncontrolled-tab-example"
                            className="mb-3 border-0"
                        >
                            <Tab eventKey="profile-details" title="Profile Details">
                                <Row className="justify-content-center">
                                    <Col md={12} lg={12}>
                                        <div className="profile-top-heading mb-3">
                                            <h2 className="heading">Profile Details</h2>
                                        </div>
                                        <h4 className="sub-heading">User Information</h4>
                                    </Col>
                                </Row>

                                <Row className="justify-content-left">
                                    <Col md={6} lg={6}>
                                        <div className="user-detail-box profile-details-content">
                                            <div className="under-profile-box">
                                                <p className="label-text">Wallet Address:</p>
                                                <p className="wallet-details-text">
                                                    {!walletAddress?.accounts && walletAddress
                                                        ? formatWalletAddress(walletAddress)
                                                        : ""}
                                                    <i
                                                        className="fa fa-copy"
                                                        onClick={() =>
                                                            copyToClipboard(walletAddress, setCopySuccess)
                                                        }
                                                        style={{
                                                            marginLeft: "10px",
                                                            cursor: "pointer",
                                                            color: "blue",
                                                        }}
                                                        title="Copy Wallet Address"
                                                    ></i>
                                                    {copySuccess && (
                                                        <span
                                                            style={{
                                                                color: "green",
                                                                marginLeft: "10px",
                                                                fontSize: "12px",
                                                            }}
                                                        >
                                                            {copySuccess}
                                                        </span>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        {localStorage.getItem("user_type") === "CUSTODIAL" ? (
                                            <div className="under-profile-box">
                                                <p className="label-text">Email or Phone:</p>
                                                <p className="wallet-details-text">
                                                    {userDetail?.verifierId || "-"}
                                                </p>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="under-profile-box">
                                            <p className="label-text">Balance:</p>
                                            <p className="wallet-details-text">
                                                {balance || "0.00"} MATIC
                                            </p>
                                            <div className="d-flex">
                                                <div className="another-balacne w-50">
                                                    <p className="wallet-details-text">
                                                        {usdcBalance}{" "}
                                                        <span className="last-text">USDC</span>
                                                    </p>
                                                </div>
                                                <div className="another-balacne w-50">
                                                    <p className="wallet-details-text">
                                                        {folioBalance}{" "}
                                                        <span className="last-text">FOLIO</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6} lg={6}>
                                        {userData && userData?.referral_code ? (
                                            <div className="user-detail-box profile-details-content">
                                                <div className="under-profile-box">
                                                    <p className="label-text">Referral URL:</p>
                                                    <p className="wallet-details-text">
                                                        {`${appUrl}/referral/${userData.referral_code}`}
                                                        <i
                                                            className="fa fa-copy"
                                                            onClick={() =>
                                                                copyToClipboard(
                                                                    `${appUrl}/referral/${userData.referral_code}`,
                                                                    setCopyReferral
                                                                )
                                                            }
                                                            style={{
                                                                marginLeft: "10px",
                                                                cursor: "pointer",
                                                                color: "blue",
                                                            }}
                                                            title="Copy Wallet Address"
                                                        ></i>{" "}
                                                        {/* <i class="fa fa-share" aria-hidden="true" onClick={() => setShowShareReferral(true)}></i> */}
                                                        {copyReferral && (
                                                            <span
                                                                style={{
                                                                    color: "green",
                                                                    marginLeft: "10px",
                                                                    fontSize: "12px",
                                                                }}
                                                            >
                                                                {copyReferral}
                                                            </span>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {/* {localStorage.getItem("user_type") === "CUSTODIAL" ? <button onClick={()=>showCheckout()} className="export-section mt-2">
                                    <span>Add funds</span>
                                </button>:""} */}

                                        {localStorage.getItem("user_type") === "CUSTODIAL" ? (
                                            <div className="export-section mt-2">
                                                <button
                                                    onClick={exportPrivateKey}
                                                    className="export-section"
                                                >
                                                    <img
                                                        src={require("../assets/images/export-icon.png")}
                                                        alt="vector"
                                                    />
                                                    <span>Export Private Key</span>
                                                </button>
                                                {privateKey && (
                                                    <div className="under-profile-box mt-3">
                                                        <p className="label-text">Private Key:</p>
                                                        <p className="wallet-details-text">
                                                            {privateKey}
                                                            <i
                                                                className="fa fa-copy"
                                                                onClick={() =>
                                                                    copyToClipboard(
                                                                        privateKey,
                                                                        setPrivatekeySuccess
                                                                    )
                                                                }
                                                                style={{
                                                                    marginLeft: "10px",
                                                                    cursor: "pointer",
                                                                    color: "blue",
                                                                }}
                                                                title="Copy Private Key"
                                                            ></i>
                                                            {privateKeySuccess && (
                                                                <span
                                                                    style={{
                                                                        color: "green",
                                                                        marginLeft: "10px",
                                                                        fontSize: "12px",
                                                                    }}
                                                                >
                                                                    {privateKeySuccess}
                                                                </span>
                                                            )}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="refresh-btn">
                                            <p className={`wallet-details-text paypal-price-message ${isRunning ? "" : "paypal-message-disable"}`}>10 USD equals {maticPriceInUSD ? Number(maticPriceInUSD).toFixed(2) : "0.00"} POL based on current rates </p>
                                            {isRunning ? <></> :
                                                <h6 onClick={() => setIsRunning(true)} className="export-section">
                                                    <span><i class="fa fa-refresh" aria-hidden="true"></i></span>
                                                </h6>}
                                        </div>
                                        <CheckOutPayPal disabledPayPalButton={!isRunning} />
                                    </Col>
                                </Row>
                            </Tab>

                            <Tab eventKey="nft-holding" title="NFT Holding">
                                
                                <div className="marketplace-area">
                                <div className="explore-top-filter under-nft-holding">
                  <Form.Group
                    className="filter-area-top"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Filter:</Form.Label>
                    <Select
                      options={statusFilterOptions}
                      defaultValue={selectedStatus}
                    
                      value={selectedStatus}
                      // defaultMenuIsOpen={true}
                      onChange={(e) => handleFilterStatus(e)}
                    />
                  </Form.Group>
                </div>



                                    <Row>
                                        {isLoading ? (
                                            <Col md={12} lg={12} xl={12} className="mb-4">
                                                <div className="loading-area text-center">

                                                    {/* <img src={require("../assets/images/loader.gif")} alt="Loading" /> */}
                                                    <span class="loading">Loading</span>
                                                </div>
                                            </Col>
                                            ) : myNfts?.data?.length > 0 ? (
                                            myNfts?.data.map(item => {
                                                return (
                                                    <Col
                                                        sm={12}
                                                        md={6}
                                                        lg={4}
                                                        xl={3}
                                                        className="margin-btm"
                                                        onClick={() => navigate(`/nft-details/${item.tokenId}`)}
                                                    >
                                                        <div className="marketplace-outer">
                                                            <div className="marketplace-header">
                                                                {item?.predictions.map(childItem => (
                                                                    <img
                                                                        className={
                                                                            item?.predictions.length === 1
                                                                                ? "nft-one-module"
                                                                                : item?.predictions.length === 2
                                                                                ? "nft-two-module"
                                                                                : ""
                                                                        }
                                                                        src={childItem.image}
                                                                        alt="NFT"
                                                                    />
                                                                ))}
                                                            </div>
                                                            <div className="marketplace-body">
                                                                <h6>
                                                                    Total Number of Bets Bundled: <span>{item?.predictionCount}</span>
                                                                </h6>
                                                                <h6>
                                                                    Total Prize Pool: <span>{item?.price / 10 ** 6} USDC</span>
                                                                </h6>

                                                                {(new Date() >= new Date(new Date(item?.predictions?.[0]?.endDateTime).getTime() - 10 * 60 * 1000)) && (item?.sold == false )&&
                                                                    <h6 className="nft_expired">
                                                                        Expired
                                                                    </h6>
                                                                }



                                                                {walletAddress && item?.owner === `${walletAddress}`?.toLowerCase() && item?.sold ? (
                                                                    <Button type="button" className="marketplace-btn" variant="unset">
                                                                        Sell
                                                                    </Button>
                                                                ) : walletAddress && item?.owner === `${walletAddress}`?.toLowerCase() ? (
                                                                    <Button type="button" className="marketplace-btn" disabled variant="unset">
                                                                        Sell
                                                                    </Button>
                                                                ) : (
                                                                    <Button type="button" className="marketplace-btn" variant="unset">
                                                                        {item?.sold ? "Sold" : "Buy"}
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                );
                                            })
                                            ) : (
                                            <Col md={12} lg={12} xl={12} className="mb-4">
                                                <div className="no-data-area main-no-data">
                                                    <img src={require("../assets/images/no-data.gif")} alt="No Data" />
                                                    <p>More NFTs Coming Soon</p>
                                                </div>
                                            </Col>
                                        )}

                                    </Row>
                                </div>
                                {myNfts?.metadata?.totalDocuments > pageLimit ? <Pagination
                                    activePage={page}
                                    itemsCountPerPage={pageLimit}
                                    totalItemsCount={myNfts?.metadata?.totalDocuments}
                                    pageRangeDisplayed={5}
                                    onChange={(e) => handlePageChange(e)}
                                    prevPageText={"Prev"}
                                    nextPageText={"Next"}
                                /> : ""}
                            </Tab>
                        </Tabs>
                       
                    </div>
                </Container>
            </section>

            <Modal
                show={showShareReferral}
                onHide={() => setShowShareReferral(false)}
                animation={false}
                centered
                className="text-center share-referral-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Share Referral</Modal.Title>
                </Modal.Header>

                <Modal.Body className="share-modal-new">
                    <span className="qr-code-box share-qr-code mt-3">
                        <img
                            src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${shareReferralUrl}`}
                        ></img>
                    </span>
                    <br />

                    <WhatsappShareButton
                        url={shareReferralUrl}
                        title={title}
                        separator=" "
                        className="Demo__some-network__share-button"
                    >
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>

                    <LinkedinShareButton
                        url={shareReferralUrl}
                        className="Demo__some-network__share-button"
                    >
                        <LinkedinIcon size={32} round />
                    </LinkedinShareButton>

                    <FacebookShareButton
                        url={shareReferralUrl}
                        quote={title}
                        className="Demo__some-network__share-button"
                    >
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>

                    <TwitterShareButton
                        url={shareReferralUrl}
                        title={title}
                        className="Demo__some-network__share-button"
                    >
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>
                </Modal.Body>
                {/* <Button type="button" className="ok-bt" varient="unset" onClick={() => {
                        setShowShareReferral(false);
                    }}
                >
                    Ok
                </Button> */}
            </Modal>
        </>
    );
};

export default ProfileDetail;
