import swal from "sweetalert";
import CheckOutPayPal from "./CheckOutPayPal";
import ReactDOM from "react-dom";
import { PAYPAL_CLIENT_ID } from "../config/config";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
const initialOptions = {
    clientId:PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  }; 

export async function FundAddModal() {
    const wrapper = document.createElement("div"); // Create a wrapper div for React content
  
    // Dynamically render React components using ReactDOM
    const root = ReactDOM.createRoot(wrapper);
  
    root.render(
      <div>
        <p>
          You don't have enough POL in your wallet to bet on predictions. Transfer more POL to your wallet or fund your account with Paypal.
        </p>
        <ul>
          <li className="mt-1">
          <PayPalScriptProvider options={initialOptions} >
            <CheckOutPayPal disabledPayPalButton={false} />
            </PayPalScriptProvider>
          </li>
        </ul>
      </div>
    );
  
    // Use SweetAlert to display the modal with the React-rendered content
    await swal({
      content: wrapper,
      icon: "info",
    //   buttons:false
    });
  }
