import { Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import moment from "moment";
import { apiService } from "../service/api.service";
import { formatAddress, formatBalance, formatBalanceWithDecimals } from "../common/connectWallet";
import Pagination from "react-js-pagination";
// import swal from "sweetalert";
import { adConfig, consttransactionurl, decimalTo, gasFeePercent, nftBundlingContract, nftBundlingListingFee } from "../config/config"
import { ThreeDotSpinner } from "./loader/index"
import { adClient } from '../common/adConfig'
import { useSelector } from "react-redux";
import ViewNFT from "./ViewNFT";
import AdComponent from "../common/AdComponent";
import swal from "sweetalert";
import Web3 from "web3";
import NFT_BUNDLING_ABI from "../common/NFT_BUNDLING_ABI.json"
import { ethers } from "ethers";
import { FundAddModal } from "../common/FundAddModal";
const TransactionManagement = () => {
    const [loader, setLoader] = useState(false)
    const [transactions, setTransactions] = useState();
    const [endDateTime, setEndDateTime] = useState("");
    const [checkedTransactions, setCheckedTransactions] = useState([]);

    const walletAddress = useSelector((state) => state.reducer.walletAddress);
    const [totalCount, settotalCount] = useState()
    const [page, setpage] = useState(0)
    const adData = adConfig["TransactionManagement"]; // Get specific page ad config
    const adDataFollowSteps = adConfig["PredictionDetail"]; // Get specific page ad config
    const [showNFTView, setShowNFTView] = useState(false);
    const web3AuthProvider = useSelector((state) => state.reducer.web3AuthProvider);
    const [showFollowSteps, setShowFollowSteps] = useState(false);
    const [nftBuyingPrince, setNftBuyingPrince] = useState('');
    const [showNftBuyingPrinceModal, setShowNftBuyingPrinceModal] = useState(false);
    const [selectedTransactions, setSelectedTransactions] = useState([]);


    useEffect(() => {
        userTransaction(page)
    }, [])
    async function userTransaction(page) {
        setLoader(true)
        try {
            const response = await apiService.userTransaction(page);
            if (response.status == 200) {
                console.log(response.data.data)
                setTransactions(response.data.data.predictionDetail)
                settotalCount(response.data.data.totalRecords)
            }
            setLoader(false)
        } catch (error) {

            setLoader(false);
            if (error?.response?.status == 401) {
                // swal({ text: "Unauthorized", button: "OK" }).then(() => {
                localStorage.clear();
                window.location.href = "/";
                // });
            } else {
            }
        }
    }

    function handlePageChange(pageNumber) {
        if (page != pageNumber - 1) {
            setpage(pageNumber - 1);
            userTransaction(pageNumber - 1)

        }

    }





    const [transactionsBetId, setTransactionsBetId] = useState([]);

    function handleTransactionChange(event, transactionContract, betId, endDate) {
        if (endDateTime && endDateTime!=endDate) {
            swal("","You can bundle only those transactions that share the same end time.","info")
            return;
        }

        setEndDateTime(endDate);
        setCheckedTransactions((prev) => {
            if (event.target.checked) {
                // Allow adding only if less than 4 items are selected
                if (prev.length < 4) {
                    return [...prev, transactionContract];
                } else {
                    swal({
                        title: "Error!",
                        text: "You can select a maximum of 4 items.",
                        icon: "error",
                        button: "OK",
                    })
                    return prev;
                }
            } else {
                // Remove the item if unchecked
                if (prev.length == 1) {
                    setEndDateTime('');
                }
                return prev.filter((id) => id !== transactionContract);
            }
        });


        setTransactionsBetId((prev) => {
            if (event.target.checked) {
                if (prev.length < 4) {
                    return [...prev, betId];
                } else {
                    return prev;
                }
            } else {
                return prev.filter((id) => id !== betId);
            }
        });
    }




    const handleBundleNftSubmit = () => {
        if (nftBuyingPrince.trim() === '' || isNaN(nftBuyingPrince) || Number(nftBuyingPrince) <= 0) {
            swal({
                // title: "Error!",
                text: "Please enter a valid price!",
                icon: "info",
                button: "OK",
            })
            return;
        }
        setShowNftBuyingPrinceModal(false); // Close modal
        nftBundling();
    };

    async function nftBundling() {
        try {

            const predictionIds = checkedTransactions.map(item => item.split("_")[1] ? Number(item.split("_")[1]) : 1000);
            const contractAddresses = checkedTransactions.map(item => item.split("_")[0]);
            let buyingPrince = nftBuyingPrince * 10 ** 6;
            buyingPrince = buyingPrince.toString();
            let nftListingFee = nftBundlingListingFee * 10 ** 18;
            nftListingFee = nftListingFee.toString();
            setShowFollowSteps(true);
            const web3 = new Web3(web3AuthProvider);
            let nftContract = new web3.eth.Contract(NFT_BUNDLING_ABI, nftBundlingContract);
            if (localStorage.getItem("user_type") == "CUSTODIAL") {
                const ethersProvider = new ethers.providers.Web3Provider(web3AuthProvider);
                const signer = ethersProvider.getSigner();
                nftContract = new ethers.Contract(nftBundlingContract, NFT_BUNDLING_ABI, signer);
                const balance = await ethersProvider.getBalance(walletAddress);
                let balacneInETH = ethers.utils.formatEther(balance);
                if (Number(balacneInETH) < (nftBundlingListingFee + 0.1)) {
                    await FundAddModal();
                    setShowFollowSteps(false);
                } else {
                    await swal({
                        text: "Are you sure you want to complete this transaction?",
                        icon: "info",
                        buttons: ["Cancel", "Confirm"]
                    }).then(async (res) => {
                        if (res) {

                            console.log("predictionIds, contractAddresses, transactionsBetId, buyingPrince------", predictionIds, contractAddresses, transactionsBetId, buyingPrince);
                            const tx = await nftContract.mintPredictionNFT(predictionIds, contractAddresses, transactionsBetId, buyingPrince, {
                                gasLimit: 1500000, // Fallback if gas estimation fails
                                value: nftListingFee
                            });
                            const receipt = await tx.wait();
                            if (receipt) {
                                console.log("==========Transaction successful, receipt:", receipt);
                                swal("Success", "NFT bundled successfully.", "success").then(res => {
                                    window.location.reload();
                                });
                                setShowFollowSteps(false);
                            } else {
                                swal("Error", "Something went wrong.", "error").then(res => {
                                    window.location.reload();
                                });
                            }
                        } else {
                            setShowFollowSteps(false);
                        }
                    })
                }
            } else {
                let gas, gasPrice;
                try {
                    gasPrice = await web3.eth.getGasPrice();
                    gasPrice = Math.ceil(Number(gasPrice) * gasFeePercent);

                    console.log("predictionIds, contractAddresses, transactionsBetId, buyingPrince------", predictionIds, contractAddresses, transactionsBetId, buyingPrince);
                    gas = await nftContract.methods.mintPredictionNFT(predictionIds, contractAddresses, transactionsBetId, buyingPrince).estimateGas({ from: walletAddress, value: nftListingFee });
                    gas = Math.ceil(Number(gas) * gasFeePercent);
                } catch (error) {

                }

                const receipt = await nftContract.methods.mintPredictionNFT(predictionIds, contractAddresses, transactionsBetId, buyingPrince).send({
                    from: walletAddress,
                    value: nftListingFee,
                    gas: gas,  // Dynamically estimated gas
                    gasPrice: gasPrice  // Current network gas price
                });
                console.log("==========Transaction successful, receipt:", receipt);
                swal("Success", "NFT bundled successfully.", "success").then(res => {
                    window.location.reload();
                });
                setShowFollowSteps(false);
            }

        } catch (error) {
            console.log("error ajit", error);
            setShowFollowSteps(false);
            swal("Error", "Something went wrong.", "error").then(res => {
                window.location.reload();
            });
        }
    }


    return (
        <>
            {window.innerWidth <= 768 ? (
                <AdComponent adId={adData.ids[3]} dimension={adData.dimensions[3]} className={"ads-banner-top"} />) :
                (
                    <AdComponent adId={adData.ids[0]} dimension={adData.dimensions[0]} className={"ads-banner-top"} />)}
            {/* <AdComponent adId={adData.ids[0]} dimension={adData.dimensions[0]} className={"ads-banner-top"} /> */}

            <AdComponent adId={adData.ids[1]} dimension={adData.dimensions[1]} className={"ads-home-right"} />

            <AdComponent adId={adData.ids[2]} dimension={adData.dimensions[2]} className={"ads-home-left"} />

            <section className="transaction-management-area">
                {loader && <> <ThreeDotSpinner /></>}
                <Container>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="justify-content-center">
                            <Col md={12} lg={12}>
                                <div className="transaction-top-section">
                                    <div className="transaction-top-heading">
                                        <h2>Transactions</h2>
                                    </div>
                                    <div>
                                        <Button className="me-2" type="button" variant="unset" disabled={checkedTransactions.length < 1} onClick={() => setShowNftBuyingPrinceModal(true)}>Create NFT Bundle</Button>
                                        {/* {!walletAddress?.accounts && walletAddress ? <Button type="button" variant="unset" onClick={() => setShowNFTView(true)}>View My NFTs</Button> : ""} */}
                                        {!walletAddress?.accounts && walletAddress ? <Button type="button" variant="unset" onClick={() => window.location.href = "/profileDetail?nft"} >View My NFTs</Button> : ""}
                                    </div>
                                </div>

                            </Col>
                        </Row>
                        <Row className=" justify-content-center">
                            <Col md={12} lg={12}>

                                <div className="audit-request-box">

                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                {transactions?.some((data) => {
                                                    const { predictionDetails, latestEntry } = data;
                                                    return !(
                                                        new Date() >= new Date(new Date(predictionDetails?.endDateTime).getTime() - 10 * 60 * 1000) ||
                                                        latestEntry?.isBundled
                                                    );
                                                }) && (
                                                        <th></th>
                                                    )}
                                                <th>Sr. No.</th>
                                                <th>Prediction Name</th >
                                                <th>Prediction Type</th>
                                                <th>Start Time</th>
                                                <th>End Time</th>
                                                <th>Amount</th>
                                                <th>Reward Amount</th>
                                                <th>Hash</th>
                                                <th>Answer</th>
                                                <th>Result</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactions && transactions.length > 0 ?
                                                <>
                                                    {transactions && transactions.map((data, index) => {
                                                        const { predictionDetails, latestEntry } = data;

                                                        return (<tr key={index} className={Number(latestEntry?.reward_amount) > 0 ? "highlight-prize" : ""}>
                                                            {((new Date() >= new Date(new Date(predictionDetails?.endDateTime).getTime() - 10 * 60 * 1000)) ||
                                                                (latestEntry?.isBundled)) ?

                                                                <>
                                                                    {transactions?.some((data) => {
                                                                        const { predictionDetails, latestEntry } = data;
                                                                        return !(
                                                                            new Date() >= new Date(new Date(predictionDetails?.endDateTime).getTime() - 10 * 60 * 1000) ||
                                                                            latestEntry?.isBundled
                                                                        );
                                                                    }) && (
                                                                            <td></td>
                                                                        )}
                                                                </> :
                                                                <td>
                                                                    <Form>
                                                                        {['checkbox'].map((type) => (
                                                                            <div key={`default-${type}`} >
                                                                                <Form.Check // prettier-ignore
                                                                                    type={type}
                                                                                    id={`default-${type}`}
                                                                                    checked={checkedTransactions.includes(data?.predictionDetails?.contract_address)}
                                                                                    onChange={(event) => handleTransactionChange(event, data?.predictionDetails?.contract_address, Number(data?.latestEntry?.bet_id), data?.predictionDetails?.endDateTime)}
                                                                                />

                                                                            </div>
                                                                        ))}
                                                                    </Form> 
                                                                </td>}
                                                            <td>{index + 1}</td>
                                                            <td><a href={"/" + predictionDetails?.data_provider + "/" + predictionDetails?.contract_address} target="_blank">{predictionDetails?.question}</a></td>
                                                            <td>{predictionDetails?.prediction_type ? predictionDetails?.prediction_type == "RISK-FREE" ? "Prize pool" : predictionDetails?.prediction_type : "N/A"}</td>
                                                            <td>{moment(predictionDetails?.startDateTime).format("MMM DD, HH:mm A")}</td>
                                                            <td>{moment(predictionDetails?.endDateTime).format("MMM DD, HH:mm A")}</td>
                                                            <td> {formatBalanceWithDecimals(latestEntry?.amount, predictionDetails?.decimal ? predictionDetails?.decimal : decimalTo)} <span className="token_symbol">{predictionDetails?.symbol} </span></td>
                                                            <td>{latestEntry?.reward_amount != 0 ? formatBalanceWithDecimals((latestEntry?.reward_amount - latestEntry?.amount), predictionDetails?.decimal ? predictionDetails?.decimal : decimalTo) : 0.00} <span className="token_symbol">{predictionDetails?.symbol} </span></td>
                                                            <td><a target="_blank" href={consttransactionurl + latestEntry?.transaction_hash}>{formatAddress(latestEntry?.transaction_hash)}</a></td>
                                                            <td>{latestEntry?.prediction.toString()}</td>
                                                            <td>{predictionDetails?.result == null ? "Not Updated" : predictionDetails?.result.toString()}</td>
                                                            <td>{moment(predictionDetails?.date).format("yyyy-MM-DD, hh:mm A")}</td>
                                                            {/* <td>
                                                                <p>{latestEntry?.mystry_code ? latestEntry.mystry_code : "-"}</p>
                                                                {latestEntry?.mystry_code ? <a className="redeem-now-btn" href="https://app.earnm.com/en/reveal/" target="_blank">Redeem Now</a> : ""}
                                                            </td> */}
                                                        </tr>)
                                                    })}
                                                </> :
                                                <tr>
                                                    <td colspan="8">
                                                        <div className="no-data-area main-no-data table-no-data">
                                                            <img src={require("../assets/images/no-data.gif")} alt="img" />
                                                            <p> No Data Found</p>
                                                        </div>
                                                    </td>
                                                </tr>


                                            }
                                        </tbody>
                                    </Table>
                                    {transactions && transactions.length > 0 ?

                                        <Pagination
                                            activePage={page + 1}
                                            itemsCountPerPage={10}
                                            totalItemsCount={totalCount}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                        : null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <ViewNFT showNFTView={showNFTView} setShowNFTView={setShowNFTView} />




            <Modal show={showNftBuyingPrinceModal} onHide={() => setShowNftBuyingPrinceModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>NFT Buying Price</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="nftPriceInput">
                            <Form.Label>NFT Price</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter NFT price"
                                value={nftBuyingPrince}
                                onChange={(e) => setNftBuyingPrince(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleBundleNftSubmit}>
                        Bundle NFT
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={showFollowSteps}
                backdrop="static"
                onHide={() => setShowFollowSteps(false)}
                centered
                className="connect-wallet-box follow-steps-popup"
            >
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="mt-0">Follow Steps </h3>
                    {window.innerWidth <= 768 ? (
                        <AdComponent adId={adDataFollowSteps.ids[2]} dimension={adDataFollowSteps.dimensions[2]} className="follow-steps-ad-mid" />
                    ) : (
                        <AdComponent adId={adDataFollowSteps.ids[3]} dimension={adDataFollowSteps.dimensions[3]} className="follow-steps-ad-mid" />
                    )}

                    <div className="step-area">
                        <div className="steps-left-area">
                            {showFollowSteps ? <div class="loader"></div> : <h2>✓</h2>}
                        </div>
                        <div className="steps-content">
                            <h6>Mint NFT</h6>
                            <p>Send transaction to Mint NFT</p>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};
export default TransactionManagement;