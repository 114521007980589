import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { Container, Row, Col, Card, Form, Button, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ConsolidatedPredictionAbi from '../common/ConsolidatedPredictionAbi.json'

const CustomPlaceBet = () => {
  const [privateKey, setPrivateKey] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [maxBet, setMaxBet] = useState("");
  const [betAmount, setBetAmount] = useState("");
  const [betAnswer, setBetAnswer] = useState("");
  const [walletBalances, setWalletBalances] = useState([]);


  const [network, setNetwork] = useState("amoy");
  const [successMessage, setSuccessMessage] = useState("");
  const [successPolMessage, setSuccessPolMessage] = useState("");
  const [errorMessagePol, setErrorMessagePol] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const walletList = [
   ]
  const rpcUrls = {
    amoy: "https://polygon-amoy-bor-rpc.publicnode.com",
    mainnet: "https://polygon-bor-rpc.publicnode.com",
  };

  useEffect(() => {
    fatchBalance();
  }, [network])

  async function fatchBalance() {
    const web3 = new Web3(rpcUrls[network]);
    const balances = await Promise.all(
      walletList.map(async (wallet) => {
        const account = web3.eth.accounts.privateKeyToAccount(wallet.privateKey);
        const balance = await web3.eth.getBalance(account.address);
        return {
          address: account.address,
          balance: web3.utils.fromWei(balance, "ether"), // Convert balance to Ether
        };
      })
    );
    setWalletBalances(balances);
  }

  const handleSendAmountSubmit = async (e) => {
    e.preventDefault();
    setSuccessPolMessage("");
    setErrorMessage("");

    try {
      const receipts = [];
      const web3 = new Web3(rpcUrls[network]);
      const account = web3.eth.accounts.privateKeyToAccount(privateKey);
      web3.eth.accounts.wallet.add(account);
      web3.eth.defaultAccount = account.address;

      const gasPrice = await web3.eth.getGasPrice();
      const adjustedGasPrice = web3.utils.toWei((2 * parseFloat(web3.utils.fromWei(gasPrice, "gwei"))).toString(), "gwei");
      const maxPriorityFeePerGas = web3.utils.toWei("30", "gwei");
      let nonce = await web3.eth.getTransactionCount(account.address, "latest");

      for (const receiverWallet of walletList) {
        try {
          const receiverAddress = receiverWallet.address;
          const balance = await web3.eth.getBalance(account.address);
          const amountInWei = web3.utils.toWei(betAmount, "ether");

          if (Number(balance) < Number(amountInWei)) {
            throw new Error(`Insufficient balance for transaction to ${receiverAddress}`);
          }

          const gasLimit = await web3.eth.estimateGas({
            from: account.address,
            to: receiverAddress,
            value: amountInWei,
          });

          const tx = {
            from: account.address,
            to: receiverAddress,
            value: amountInWei,
            gas: gasLimit,
            maxPriorityFeePerGas: maxPriorityFeePerGas,
            maxFeePerGas: adjustedGasPrice,
            nonce: nonce,
          };

          const signedTx = await web3.eth.accounts.signTransaction(tx, privateKey);
          const receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);

          receipts.push(receipt.transactionHash);
          nonce++; // Increment nonce for the next transaction
        } catch (txError) {
          console.error(`Error processing transaction for ${receiverWallet.address}:`, txError);
        }
      }

      if (receipts.length > 0) {
        setSuccessPolMessage(`Transaction successful! TX Hashes: ${receipts.join(", ")}`);
      } else {
        setErrorMessagePol("All transactions failed. Please check the logs.");
      }
    } catch (error) {
      console.error("Error sending transactions:", error);
      setErrorMessagePol("Failed to send transactions. Please try again.");
    }
  };



  async function handleBetSubmit(e) {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");

    // Validate contract address
    if (!Web3.utils.isAddress(contractAddress)) {
      setErrorMessage("Invalid receiver address.");
      return;
    }

    const web3 = new Web3(rpcUrls[network]);

    try {
      for (let betIndex = 0; betIndex < maxBet; betIndex++) {

        for (const [index, sendWallet] of walletList.entries()) {
          const transactions = []; // Store promises for all transactions
          const account = web3.eth.accounts.privateKeyToAccount(sendWallet.privateKey);
          web3.eth.accounts.wallet.add(account);
          web3.eth.defaultAccount = account.address;

          const predictionContract = new web3.eth.Contract(ConsolidatedPredictionAbi, contractAddress);

          try {
            // Prepare the transaction
            const betvalue = index == 0 ? 0.3 : 0.4;
            const betAnswerOptions = betAnswer.split(",");
            const randomIndex = (index % betAnswerOptions.length);
            const betAnswerSelect = betAnswerOptions[randomIndex] || betAnswerOptions[0];
            console.log("betAnswerSelect--->", betAnswerSelect, " betvalue-------->", betvalue, "wallet index", index, "betIndex---->", betIndex);

            const data = predictionContract.methods.betRiskfree(betIndex, betAnswerSelect).encodeABI();

            const gasLimit = await web3.eth.estimateGas({
              from: account.address,
              to: contractAddress,
              value: betvalue * 10 ** 18,
              data: data,
            });

            const gasPrice = await web3.eth.getGasPrice();
            const nonce = await web3.eth.getTransactionCount(account.address, "latest");

            const tx = {
              from: account.address,
              to: contractAddress,
              value: betvalue * 10 ** 18,
              gas: gasLimit,
              gasPrice: gasPrice,
              data: data,
              nonce: nonce,
            };

            // const signedTxPromise = web3.eth.accounts.signTransaction(tx, sendWallet.privateKey).then(signedTx => {
            //   return web3.eth.sendSignedTransaction(signedTx.rawTransaction);
            // });

            // transactions.push(signedTxPromise);
            // // If this is the last wallet, await its transaction
            // if (index === walletList.length - 1) {
            //   // Wait for all non-last wallet transactions to complete
            //   await Promise.allSettled(transactions).then(results => {
            //     console.log("All non-last wallet transactions completed:", results);
            //   });
            //   const receipt = await signedTxPromise;
            // } 

            // Sign the transaction
            const signedTx = await web3.eth.accounts.signTransaction(tx, sendWallet.privateKey);

            // Send the signed transaction
            const receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
            console.log(`Bet ${betIndex} success! TX Hash: ${receipt.transactionHash}`);

            setSuccessMessage(`Bet ${betIndex} success!`);
          } catch (txError) {
            console.error(`Error sending bet ${betIndex}:`, txError);
            setErrorMessage(`Failed to send bet ${betIndex}. Please try again.`);
          }
        }
      }
    } catch (error) {
      console.error("Error submitting bets:", error);
      setErrorMessage("Failed to submit bets. Please try again.");
    }
  }



  return (
    <Container className="mt-5">
      <Row className="justify-content-center" md={2}>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Network:</Form.Label>
            <Form.Select aria-label="Select"
              as="select"
              value={network}
              onChange={(e) => setNetwork(e.target.value)}
              required>
              <option value="amoy">Polygon Amoy (Testnet)</option>
              <option value="mainnet">Polygon Mainnet</option>
            </Form.Select>
          </Form.Group>
          <div style={{ marginTop: "20px", fontWeight: "bold" }}>
          Total Balance: {walletBalances.reduce((total, wallet) => total + parseFloat(wallet.balance), 0).toFixed(2)} POL
          </div>

        </Col>
        <Col md={6}>
          {walletBalances.map((item, index) => (
            <div key={index} style={{ marginBottom: "1px" }}>
              {item.address} <b>{parseFloat(item.balance).toFixed(2)} POL</b>
            </div>
          ))}
        </Col>

      </Row>
      <Row className="justify-content-center" md={10}>
        <Col md={6}>
          <Card>
            <Card.Body>
              <h2 className="card-title text-center mb-4">Custom Place Bets</h2>
              <Form onSubmit={handleBetSubmit}>

                <Form.Group className="mb-3">
                  <Form.Label>Contract Address:</Form.Label>
                  <Form.Control
                    type="text"
                    value={contractAddress}
                    onChange={(e) => setContractAddress(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Bet Answer:</Form.Label>
                  <Form.Control
                    type="text"
                    value={betAnswer}
                    onChange={(e) => setBetAnswer(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Max Bet:</Form.Label>
                  <Form.Control
                    type="number"
                    value={maxBet}
                    onChange={(e) => setMaxBet(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button type="submit" variant="primary" className="w-100">
                  Start Bet
                </Button>
              </Form>
              {successMessage && (
                <Alert variant="success" className="mt-3">
                  {successMessage}
                </Alert>
              )}
              {errorMessage && (
                <Alert variant="danger" className="mt-3">
                  {errorMessage}
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Body>
              <h2 className="card-title text-center mb-4">Send Amounts</h2>
              <Form onSubmit={handleSendAmountSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Master Private Key:</Form.Label>
                  <Form.Control
                    type="password"
                    value={privateKey}
                    onChange={(e) => setPrivateKey(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Bet Amount (POL):</Form.Label>
                  <Form.Control
                    type="number"
                    value={betAmount}
                    onChange={(e) => setBetAmount(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button type="submit" variant="primary" className="w-100">
                  Send Amount to Bet
                </Button>
              </Form>
              {successPolMessage && (
                <Alert variant="success" className="mt-3">
                  {successPolMessage}
                </Alert>
              )}
              {errorMessagePol && (
                <Alert variant="danger" className="mt-3">
                  {errorMessagePol}
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

};
export default CustomPlaceBet;