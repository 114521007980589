import { PayPalButtons } from "@paypal/react-paypal-js";
import { apiService } from "../service/api.service";
import swal from "sweetalert";

const CheckOutPayPal = ({ disabledPayPalButton }) => {
    async function createOrder() {
        try {
            const response = await apiService.createPaypalOrder();
            console.log("response---------->", response);
            return response.data.data.order_id;
        } catch (error) {

            console.log("Error creating order:", error);
            swal({
                title: "Error!",
                text: error.response.data.error,
                icon: "error",
                button: "OK",
            }).then(res=>{
                window.location.reload();
            });
            throw error;
            // return;
        }
    }

    async function onApprove(data) {
        try {
            const response = await apiService.capturePaypalOrder({ orderId: data.orderID });
            console.log("response---------->", response);
            swal({
                title: "Transaction Successful!",
                text: `Thank you, ${response.data.payer.name.given_name}! Your transaction ID is ${response.data.id}. It may take up to 10 minutes to reflect in your wallet.`,
                icon: "success",
                button: "OK",
            }).then(res=>{
                window.location.reload();
            });;
        } catch (error) {
            console.error("Error creating order:", error);
            throw error; // Rethrow the error if necessary
        }
    }

    return (<>
        <PayPalButtons
            className="btn-paypal"
            disabled={disabledPayPalButton}
            createOrder={createOrder}
            onApprove={onApprove}
            fundingSource="paypal"
        />
    </>)
}
export default CheckOutPayPal;